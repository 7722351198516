import * as React from "react"

import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <section className={`fix-header`}>
      <div className={`content`}>
        <h1>404: Not Found</h1>
        <p>It seems like you can't find what you're looking for. (too deep?)</p>
        <Link to={`/`}><button>Explore from Home</button></Link>
      </div>
    </section>
  </>
)

export default NotFoundPage